.card {
  all: initial;
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  border: rgb(204, 204, 204) 0.1vh solid;
  width: 13.5rem;
  height: 20rem;

  &:hover {
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.4);
    background-color: hsl(0, 0%, 97%);
  }

  &-image {
    width: 100%;

    &-img {
      width: 100%;
      border-radius: 5px;
    }
  }

  &-date {
    font-size: 0.8rem;
    color: #666;
    margin-top: 8px;
    margin-bottom: 1px;
  }

  &-hour {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 8px;
  }

  &-title {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &-popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    justify-content: center;
    flex-direction: row;
    align-content: center;

    background-color: #bbdefb;
    height: 100%;
    padding: 15px;
    gap: 5px;
    backdrop-filter: blur(15px); /* Adjust the blur amount as needed */
    background-color: rgba(83, 83, 83, 0.7); /* Adjust the transparency here */
    padding-top: 2%;
    cursor: default;
    z-index: 1;

    &-img {
      display: flex;

      justify-content: center;
      flex-direction: row;
      align-content: center;
      height: 100%;
      padding: 15px;
      gap: 5px;
      &-image {
        border-radius: 1vh;
      }

      &-buttons {
        display: flex;
        flex-direction: column;

        &-btn {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
