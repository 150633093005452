.button {
  position: relative;
  overflow: hidden;
  height: 2rem;
  padding: 0 1rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
}

.button:hover .button-content {
  color: black;
  font-weight: 900;
  transform: scaleX(0);
  transition: all 0.475s;
  transform-origin: 0 50%;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgb(255, 255, 255) 10.8%,
    rgb(203, 202, 202) 94.3%
  );
  transition: all 0.475s;
}

.round-btn-color {
  position: relative;
  overflow: hidden;
  height: 2rem;
  padding: 0 1rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.round-btn-content-color {
	font-size: medium;
  font-weight: 600;
}

.round-btn-color:hover .round-btn-content-color {
  color: black;
  font-weight: 900;
  transform: scaleX(0);
  transition: all 0.475s;
  transform-origin: 0 50%;
}

.round-btn-color::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgb(255, 255, 255) 10.8%,
    rgb(203, 202, 202) 94.3%
  );
  transition: all 0.475s;
}
