.datepicker {
  width: 250px;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(#000, 0.2), 0 5px 10px 0 rgba(#000, 0.1);
  padding: 1rem;
  background-color: #fdfdfd;
}

.datepicker-top {
  margin-bottom: 1rem;

  &-month-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background-color: #fff;
      border-radius: 12px;
      width: 2.5rem;
      height: 2.5rem;
      box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.5),
        inset 2px 2px 3px rgba(250, 121, 1, 0.12);
      &:hover {
        cursor: pointer;
      }
    }

    &-month-name {
      font-weight: 600;
    }
  }
}

.datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 1rem;

  &-day {
    color: orange;
    font-size: 0.875em;
    font-weight: 500;
    justify-self: center;
  }

  &-date {
    font: inherit;
    border: 0;
    padding: 0;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-weight: 600;
    border: 2px solid transparent;
    background-color: transparent;
    justify-self: center;
    cursor: pointer;
    &:focus {
      outline: 0;
      color: orange;
      border: 2px solid orange;
    }
    box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.5),
      inset 2px 2px 3px rgba(146, 146, 146, 0.12);
  }

  &-current-day {
    color: rgb(255, 13, 0);
    border-color: white;
    background-color: white;
    &:focus {
      background-color: orange;
      color: white;
      border-color: white;
    }
  }

  &-event {
    color: orange;
  }
}

.calendar-button-faded {
  color: gray;
}

.datepicker-events {
  margin: 2vh 0 0 0;
  padding: 0.8vh 0.8vh 0.8vh 0.8vh;
  border-radius: 5px;
  background-color: orange;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(255, 123, 0, 0.12);
  display: flex;
  flex-direction: column;
  &-date {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5vh;
  }

  &-event {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 1vh 0 0 0;
    padding: 0.5vh 0.5vh 0.5vh 0.5vh;
    background-color: white;
    color: orange;
  }
}
