.boxOfOptButtons {
  display: none;
  position: absolute;
  width: auto;
  background-color: white;
  padding: 20% 30%;
  transform: translateX(-10%);
  top: 100%;
}

.buttonOpt {
  text-decoration: none;
  color: black;
  font-family: "Chivo Mono", monospace;
  font-style: italic;
  text-align: left;
  position: relative;
  font-size: 1.5vh;
  margin: 15% 0;
}

.buttonOpt:hover {
  font-weight: bolder;
}
