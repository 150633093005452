.card-description {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3vh 5vh;

  &-header {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-backBtn {
      width: 5%;

      &:hover {
        cursor: pointer;
      }
    }

    &-title {
      width: 95%;
      display: flex;
      align-items: center;
      font-size: x-large;
      font-weight: 700;
      padding-left: 3vh;
    }
  }

  &-body {
    display: flex;
    flex-direction: row;

    &-image {
      padding: 4vh 1vh;
      &-img {
        border-radius: 2vh;
      }
    }

    &-comment {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 5vh 5vh;

      &-text {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        &-title {
          font-size: large;
          font-weight: 500;
        }

        &-content {
          text-align: justify;
          text-indent: 4vh;
        }

        &-commentAuthor {
          text-align: right;
					font-size: small;
					font-weight: 300;
					font-style: italic;
        }
      }

      &-buttons {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
