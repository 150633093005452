.container {
  display: grid;
  gap: 0px 0px;
  grid-template-areas:
    "logo navBar navBar"
    "logo body body"
    "footer footer footer";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
  background-color: purple;

  font-family: Inter, Roboto, "Helvetica Neue", "Arial Nova", "Nimbus Sans",
    Arial, sans-serif;

  &-logo {
    grid-area: logo;
    background-color: transparent;
    position: fixed;
    max-width: 30vh;
    padding: 9vh 8vh 0 10vh;
  }

  &-navBar {
    grid-area: navBar;
    background-color: white;
    position: fixed;
    padding: 7vh 0 0 0;
    margin-left: 38vh;
    width: 100%;
    margin-bottom: 0;
    z-index: 1;
  }

  &-body {
    grid-area: body;
    background-color: white;
    padding: 15vh 0 0 39vh;
  }

  &-footer {
    grid-area: footer;
    background-color: black;
  }
}
