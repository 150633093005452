.navBar {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-bottom: 1vh;
	border-bottom: 0.13em solid black;
	width: 70%;

  &-box {
    position: relative;
    margin: 1% 4% 1% 0.3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;

    &-button {
      font-size: 2.3vh;
      font-family: "Chivo Mono", monospace;
      font-style: italic;
      text-align: left;
      position: relative;
      white-space: nowrap;
      background-color: transparent;
      border: 0cap;
    }

    &-button:hover {
      font-weight: bolder;
      cursor: pointer;
    }
  }

  &-box:hover .boxOfOptButtons {
    display: flex;
    flex-direction: column;
  }

  &-box:hover &-box-button {
    font-weight: bolder;
  }
}

.boldButton {
  font-weight: bolder;
}

.noBorder {
  border-bottom: 0em solid black;
}
