.contacts {
  display: inline-flex;

  &-title {
    font-weight: 900;
    font-size: small;
    margin-right: 5%;
  }

  &-list {
    display: flex;
    flex-direction: column;

    &-element {
      display: inline-flex;
      font-size: smaller;
			
      &-title {
        font-weight: 700;
				font-size: smaller;
        margin-right: 2%;
        white-space: nowrap;
      }

      &-email {
      }
    }
  }
}
