.paragraphComponent {
  display: flex;
  flex-direction: column;
  margin: 4vh 0 0 0;

  &-title {
    margin: 0 0 0 0;
  }

  &-paragraph {
    margin: 2vh 0 0 0;
		text-indent: 3vh;
		text-align: justify;
  }

  &-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2vh 0 0 0;
  }

  &-sub {
    margin: 2vh 0 0 0;

    &-title {
      margin: 0 0 0.5vh 0;
    }

    &-paragraph {
      margin: 0 0 0 0;
    }

    &-sub {
      margin: 0 0 0 0;
      display: flex;
      flex-direction: row;

      &-title {
        margin: 0 0 0 0;
      }

      &-paragraph {
        margin: 0 0 0 0.5vh;
      }
    }
  }
}
