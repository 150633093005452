.proximasAtividades {
  display: flex;
  flex-direction: row;
  padding: 0 0 10vh 2vh;

  &-events {
    width: 70%;
    &-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 4vh;

      &-title {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 2.5vh;
        text-align: center;
      }

      &-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
        gap: 20px;
      }
    }
  }

  &-calendar {
    padding: 7vh 0 0 0;
    margin: 0 0 0 53%;
    position: fixed;
  }
}
