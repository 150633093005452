.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;

  padding: 1% 10%;
  margin: 0;
  color: white;

  &-contacts {
    margin: 1%;
  }

  &-partner {
    margin: 1%;
  }

  &-donations {
    margin: 1%;
  }

  &-socials {
    display: inline-flex;
    justify-content: space-between;
    min-width: 15vh;
    margin: 1%;
  }
}
